/* eslint-disable no-param-reassign */
import map from 'ramda/src/map'
import slice from 'ramda/src/slice'
import forEach from 'ramda/src/forEach'
import length from 'ramda/src/length'
import addIndex from 'ramda/src/addIndex'
import rotateArray from 'root/src/shared/util/rotateArray'
import isMobile from 'root/src/shared/util/isMobile'

export default (handlerProps) => {
	const {
		sliderRef, positionArr, startItem, setPosition, prevWidth, onChangeItemCallbackArr,
		step, position, item, setItem, intervalDuration, intervalMultiplier, showItems,
		infinite, children, stepMultiplier, isMobileRes = false, setFinalPosition, renderSliderItem,
		startMoveRef, deltaPositionX, breakpointsArr,
	} = handlerProps
	const commonLogic = () => {
		if (sliderRef.current) {
			const { scrollWidth, children: dragChildren } = sliderRef.current
			const childrenLength = length(dragChildren)
			step.current = childrenLength > 1 ? scrollWidth / childrenLength : scrollWidth

			positionArr.current = addIndex(map)(
				(_item, index) => -step.current * index * stepMultiplier, breakpointsArr,
			)

			prevWidth.current = window.innerWidth
		}
	}

	const setPositionEnd = () => {
		const pos = positionArr.current[startItem]
		setPosition(pos)
		setFinalPosition(pos)
	}

	const scrollPosition = (moveItems, totalMove = 0) => {
		forEach((callback) => { callback() }, onChangeItemCallbackArr.current)
		const moveToItem = item + moveItems
		const destination = positionArr.current[moveToItem]
		const currentPosition = position + totalMove
		setPosition(currentPosition)
		const diff = currentPosition - destination
		if (diff) {
			const direction = diff < 0 ? -1 : 1
			const moveDistance = (Math.ceil(Math.abs(
				diff / intervalDuration,
			)) * intervalMultiplier || 1) * direction
			let nextPosition = Math.round(currentPosition + moveDistance)
			const interval = setInterval(() => {
				setPosition(nextPosition)
				nextPosition += moveDistance
				if (
					(Math.round(nextPosition + destination)
					<= (moveDistance + 1) * 2)
				) {
					clearInterval(interval)
					setItem(moveToItem)
					setPosition(destination)
					setFinalPosition(destination)
				}
			})
		}
	}

	return {
		handleMount: (isRootComponent) => {
			commonLogic()
			if (isRootComponent) {
				setPositionEnd()
			}
		},
		handleSize: (e) => {
			if (prevWidth.current !== e.target.innerWidth) {
				commonLogic()
				setPositionEnd()
				if (!infinite) {
					setItem(startItem)
				}
			}
		},
		scrollPosition,
		infiniteCarouselRender: () => {
			const rotatedArray = slice(
				0,
				showItems,
				infinite ? rotateArray(
					item - Math.ceil(showItems / 2) + 1 * isMobileRes,
					children,
				) : children,
			)
			return addIndex(map)(
				renderSliderItem({
					showItems, isMobileRes, startItem, item, onChangeItemCallbackArr, scrollPosition,
				}),
				rotatedArray,
			)
		},
		handleOnStart: (e) => {
			let { clientX } = e
			clientX = clientX || e.changedTouches[0].clientX
			startMoveRef.current = clientX
		},
		handleOnStop: (e) => {
			let { clientX } = e
			clientX = clientX || e.changedTouches[0].clientX
			if (!isMobile()) {
				e.stopPropagation()
			}
			const move = clientX - startMoveRef.current
			const minMove = sliderRef.current.scrollWidth / 18
			deltaPositionX.current = -(move + position)
			const shouldMove = Math.abs(move) >= minMove ? 1 : 0
			// eslint-disable-next-line no-nested-ternary
			const direction = move > 0 ? -1 : move < 0 ? 1 : 0
			const nextItem = direction * shouldMove + item
			const withinBorders = nextItem >= 0 && nextItem <= length(positionArr.current) - 1

			if (withinBorders) {
				scrollPosition(direction * shouldMove, move)
			}

			deltaPositionX.current = 0
		},
	}
}
