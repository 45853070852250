import React, { memo, useState, useRef, useEffect, Fragment } from 'react'
import useInterval from 'root/src/client/web/hooks/useInterval'
import classNames from 'classnames'
import length from 'ramda/src/length'
import addIndex from 'ramda/src/addIndex'
import map from 'ramda/src/map'
import range from 'ramda/src/range'
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import Draggable from 'react-draggable'
import { comparePropsFn } from 'root/src/shared/util/stateCompare'
import linkConnector from 'root/src/client/logic/app/connectors/linkConnector'
import isMobile from 'root/src/shared/util/isMobile'
import Icon from 'root/src/client/web/base/Icon'
import { colorsNew } from 'root/src/client/web/commonStyles'
import dynamic from 'next/dynamic'
import styles from './styles'
import logic from './logic'

const NavButtons = dynamic(() => import('./NavButtons'))

const Slider = memo(({
	classes, children, sliderWidth, infinite, showItems,
	isMobileRes, intervalMultiplier = 7, intervalDuration = 245,
	startItem = 0, infiniteMultiplier = 50, stepMultiplier = 1,
	dragActive, dots, renderCaption, isDraggable, sliderItems,
	renderSliderItem, sliderItemsStyle, scrollItemBreakpoints, layoutRow,
	assigneesArrow, smoothDrag, assigneesOverflow, childrenLength, dashes, autoScroll,
	className, arrows, arrowsFixed, arrowWidth = 42, thresholdArrows, arrowsMargin, arrowsPadding,
	containerClassName,
}) => {
	const start = infinite
		? 1 + ((childrenLength || children.length) * infiniteMultiplier / 2)
		: startItem
	const [item, setItem] = useState(start)
	const [position, setPosition] = useState(start)
	const [finalPosition, setFinalPosition] = useState(0)
	const breakpointsNumber = scrollItemBreakpoints || length(children)
	const breakpointsArr = new Array(infinite
		? breakpointsNumber * infiniteMultiplier
		: breakpointsNumber).fill(0)
	const positionArr = useRef(breakpointsArr)
	const prevWidth = useRef(0)
	const sliderRef = useRef()
	const step = useRef(0)
	const onChangeItemCallbackArr = useRef([])
	const startMoveRef = useRef(0)
	const deltaPositionX = useRef(0)
	const draggableComponent = useRef()
	const [isAutoScroll, setIsAutoScroll] = useState(true)

	const handlerProps = {
		/* eslint-disable object-property-newline */
		sliderRef, positionArr, startItem: start, setPosition, prevWidth,
		onChangeItemCallbackArr, step, position, item, setItem,
		intervalDuration, intervalMultiplier, showItems, infinite,
		children, stepMultiplier, isMobileRes, setFinalPosition, renderSliderItem,
		startMoveRef, deltaPositionX, breakpointsArr,
		/* eslint-enable object-property-newline */
	}

	const {
		handleSize, handleMount, scrollPosition, infiniteCarouselRender, handleOnStart, handleOnStop,
	} = logic(handlerProps)

	useEffect(() => {
		handleMount(true)
		const sizeHandler = handleSize
		window.addEventListener('resize', sizeHandler)
		return () => {
			window.removeEventListener('resize', sizeHandler)
		}
	}, [breakpointsNumber])

	useInterval(
		() => isAutoScroll && scrollPosition(1),
		autoScroll ? 5000 : null,
	)

	const SliderComponent = dragActive ? Draggable : 'div'
	const sliderComponentProps = dragActive
		? {
			onDrag: (e) => {
				if (!isMobile()) e.preventDefault()
			},
			ref: draggableComponent,
			onStart: handleOnStart,
			position: { x: position, y: 0 },
			onStop: handleOnStop,
			axis: 'x',
		} : smoothDrag ? {
			style: {
				transform: `translateX(${position || 0}px)`,
				transition: 'transform 0.5s linear',
			},
		} : { style: { transform: `translateX(${position || 0}px)` } }

	const showArrowsFromThreshold = () => {
		if (!thresholdArrows) {
			return true
		}
		return children.length <= showItems
	}

	return (
		<div className={classNames(
			classes.wrapper,
			{ [classes.wrapperLayoutRow]: layoutRow },
			className,
		)}
		>
			{(arrows || arrowsFixed) && showArrowsFromThreshold() && item !== 0 && (
				<button
					type="button"
					onClick={() => scrollPosition(-1)}
					style={{
						width: arrowWidth,
						margin: arrowsMargin,
						padding: arrowsPadding,
					}}
					className={classNames(
						classes.arrowContainer,
						arrowsFixed ? classes.arrowLeftContainerFixed : classes.arrowLeftContainer,
						{	[classes.assigneesArrowLeft]: assigneesArrow },
					)}
				>
					<Icon size={25} icon={faChevronLeft} color={colorsNew.gray3} />
				</button>
			)}
			<div
				className={classNames(classes.container, {
					[classes.assigneesOverflow]: assigneesOverflow,
					[containerClassName]: Boolean(containerClassName),
				})}
			>
				<SliderComponent {...sliderComponentProps}>
					<Fragment>
						<div
							ref={(node) => { if (node) sliderRef.current = node }}
							className={classNames(
								classes.items,
								{ [classes.dragActiveItems]: dragActive },
								sliderItemsStyle,
							)}
							style={isDraggable
								? (dragActive && !arrowsFixed)
									? { width: `${sliderWidth}vw` } : {}
								: {
									width: (sliderWidth && !arrowsFixed) ? `${sliderWidth}vw` : '100%',
									transform: infinite ? `translateX(${-finalPosition || 0}px)` : 'initial',
								}}
						>
							{showItems ? infiniteCarouselRender(handlerProps) : children}
						</div>
						{renderCaption && (
							<div
								className={classNames(
									classes.itemsText,
									{ [classes.dragActiveItemsText]: dragActive },
								)}
								style={dragActive ? { width: `${sliderWidth}vw` } : {}
								}
							>
								{addIndex(map)(renderCaption, sliderItems)}
							</div>
						)}
					</Fragment>
				</SliderComponent>
				{dashes && (
					<NavButtons
						{...{ classes, scrollPosition, isAutoScroll, setIsAutoScroll }}
					/>
				)}
				{dots && dragActive && (
					<div className={classes.dotsContainer}>
						{addIndex(map)((_item, idx) => (
							<div
								key={idx}
								onClick={() => {
									scrollPosition(-item + idx)
								}}
								className={classNames(classes.dot, { [classes.activeDot]: idx === item })}
							/>
						), range(0, breakpointsNumber))}
					</div>
				)}
			</div>
			{(arrows || arrowsFixed) && showArrowsFromThreshold() && item !== breakpointsNumber - 1 && (
				<button
					type="button"
					onClick={() => scrollPosition(1)}
					style={{
						width: arrowWidth,
						margin: arrowsMargin,
						padding: arrowsPadding,
					}}
					className={classNames(
						classes.arrowContainer,
						arrowsFixed ? classes.arrowRightContainerFixed : classes.arrowRightContainer,
						{ [classes.assigneesArrowRight]: assigneesArrow },
					)}
				>
					<Icon size={25} icon={faChevronRight} color={colorsNew.gray3} />
				</button>
			)}
		</div>
	)
})

export default linkConnector(memo(Slider, comparePropsFn([
	'sliderWidth', 'infinite', 'showItems',
	'isMobileRes', 'intervalMultiplier', 'intervalDuration',
	'startItem', 'infiniteMultiplier', 'stepMultiplier',
	'dragActive', 'dots', 'arrows', 'arrowsFixed', 'isDraggable', 'sliderItems',
	'sliderItemsStyle', 'scrollItemBreakpoints', 'layoutRow',
	'assigneesArrow', 'smoothDrag', 'assigneesOverflow', 'children', 'containerClassName',
])), styles, 'MuiSlider')
