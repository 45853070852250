import {
	purple, ltGtMediaQuery, gtSmMediaQuery, secondaryColor,
} from 'root/src/client/web/commonStyles'

export default {
	wrapper: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		position: 'relative',
		alignItems: 'center',
	},
	wrapperLayoutRow: {
		position: 'relative',
	},
	container: {
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: 'inherit',
		width: '100%',
		boxSizing: 'border-box',
	},
	items: {
		position: 'relative',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		'@media(max-width: 1280px)': {
			maxWidth: 1100,
		},
		'@media(max-width: 1100px)': {
			maxWidth: 1000,
		},
		'@media(max-width: 1000px)': {
			maxWidth: 950,
		},
		margin: '0 auto',
	},
	itemsText: {
		display: 'flex',
		justifyContent: 'space-around',
		'@media(max-width: 1280px)': {
			maxWidth: 1100,
		},
		'@media(max-width: 1100px)': {
			maxWidth: 1000,
		},
		'@media(max-width: 1000px)': {
			maxWidth: 950,
		},
		margin: '0 auto',
	},
	dragActiveItems: {
		maxWidth: 'initial',
	},
	dragActiveItemsText: {
		maxWidth: 'initial',
	},
	dotsContainer: {
		display: 'flex',
		justifyContent: 'center',
		margin: [[35, 0]],
		[ltGtMediaQuery]: {
			margin: [[15, 0, 35]],
		},
	},
	dot: {
		height: 14,
		width: 14,
		margin: [[0, 12]],
		borderRadius: 20,
		cursor: 'pointer',
		backgroundColor: secondaryColor,
	},
	activeDot: {
		backgroundColor: purple,
	},
	arrow: {
		height: '100%',
		width: '100%',
	},
	arrowRight: {
		transform: 'rotate(180deg)',
	},
	arrowContainer: {
		background: 'none',
		border: 'none',
		display: 'flex',
		alignItems: 'center',
		alignSelf: 'center',
		justifySelf: 'center',
		height: 42,
		position: 'absolute',
		zIndex: 1000,
		cursor: 'pointer',
	},
	arrowLeftContainer: {
		position: 'initial',
		height: '100%',
		justifyContent: 'center',
	},
	arrowRightContainer: {
		position: 'initial',
		height: '100%',
		justifyContent: 'center',
	},
	arrowLeftContainerFixed: {
		justifyContent: 'flex-start',
		left: '-15%',
	},
	arrowRightContainerFixed: {
		justifyContent: 'flex-end',
		right: '-15%',
	},
	assigneesArrowRight: {
		top: '43%',
		right: '-6%',
	},
	assigneesArrowLeft: {
		top: '43%',
		left: '-6%',
	},
	assigneesOverflow: {
		overflow: 'hidden',
	},
	buttonsContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 40,
	},
	navButton: {
		padding: [[5, 0]],
		background: 'transparent',
		border: 0,
		outline: 0,
		[gtSmMediaQuery]: {
			cursor: 'pointer',
		},
	},
	innerButton: {
		width: 100,
		margin: [[0, 20]],
	},
	outherButton: {
		width: 30,
	},
}
